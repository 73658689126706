import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
import { getAuth } from "firebase/auth";

// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyA8yumueX7KY9B4-bWja3i3C07YxPE08n8",
  authDomain: "mzu-network-manager.firebaseapp.com",
  projectId: "mzu-network-manager",
  storageBucket: "mzu-network-manager.appspot.com",
  messagingSenderId: "515838707",
  appId: "1:515838707:web:76726d5028f69412fadd86",
  measurementId: "G-FT62PJMWPL",
};
const app = initializeApp(firebaseConfig);

const db = getFirestore();

const auth = getAuth(app);

export { db, auth };
