import { db } from "../../utils/firebase_config";
import { collection, getDocs, onSnapshot } from "firebase/firestore";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import VlanDetail from "./vlanDetail_component";
import {
  CollectionIcon,
  PencilAltIcon,
  PencilIcon,
} from "@heroicons/react/solid";

const VlanList = () => {
  const collectionRef = collection(db, "vlan");
  const [vlanList, setVlanList] = useState([]);
  useEffect(() => {
    onSnapshot(collectionRef, (querySnapshot) => {
      const vlanList = [];
      querySnapshot.forEach((doc) => {
        var data = doc.data();
        var vlanId = data.name;
        vlanId = parseInt(vlanId.substring(4));

        vlanList.push({ id: doc.id, ...doc.data(), index: vlanId });
      });
      vlanList.sort((a, b) => a.index - b.index);
      setVlanList(vlanList);
    });

    // const getData = getDocs(collectionRef).then((snapshot) => {
    //   setVlanList(
    //     snapshot.docs.map((doc) => {
    //       return {
    //         id: doc.id,
    //         ...doc.data(),
    //       };
    //     })
    //   );
    // });
    console.log(vlanList);
  }, []);

  return (
    <div class="flex flex-col mt-2 ml-5 ">
      <div class="py-2 -my-2 overflow-x-auto sm:-mx-6 sm:px-6 lg:-mx-8 lg:px-8">
        <div class="inline-block min-w-full overflow-hidden align-middle border-b border-gray-200 shadow sm:rounded-lg">
          <table class="min-w-full">
            <thead className="bg-gray-500">
              <tr>
                <th class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50">
                  Name
                </th>
                <th class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50">
                  Subnet
                </th>
                <th class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50">
                  Description
                </th>
                <th class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50">
                  Status
                </th>
                <th class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50">
                  Actions
                </th>
              </tr>
            </thead>

            <tbody class="bg-white">
              {vlanList.map((vlan) => (
                <tr className="py-10">
                  <td class="px-6 py-4 whitespace-no-wrap border-b border-gray-200">
                    <div class="flex items-start">
                      <div class="">
                        <div class="text-sm font-medium leading-5 text-gray-900">
                          {vlan.name}
                        </div>
                      </div>
                    </div>
                  </td>

                  <td class="px-6 py-4 whitespace-no-wrap border-b border-gray-200">
                    <div class="text-sm leading-5 text-gray-500">
                      {vlan.subnet}
                    </div>
                  </td>
                  <td class="px-6 py-4 whitespace-no-wrap border-b border-gray-200">
                    <div class="text-sm leading-5 text-gray-500">
                      {vlan.description}
                    </div>
                  </td>

                  <td class="px-6 py-4 whitespace-no-wrap border-b border-gray-200">
                    {vlan.status === "active" ? (
                      <span class="inline-flex px-2 text-xs font-semibold leading-5 text-green-800 bg-green-100 rounded-full">
                        Active
                      </span>
                    ) : (
                      <span class="inline-flex px-2 text-xs font-semibold leading-5 text-green-800 bg-red-100 rounded-full">
                        InActive
                      </span>
                    )}
                  </td>
                  <Link to={`/vlan/${vlan.id}`} className="px-3">
                    <button
                      class="bg-white text-gray-800 font-bold rounded border-b-2
                     border-green-500 hover:border-green-600 hover:bg-green-500
                      hover:text-white shadow-md py-1 px-4 inline-flex items-center"
                    >
                      <div className="flex flex-col">
                        <span class="mr-2">Detail</span>
                        <CollectionIcon height="20" />
                      </div>
                    </button>
                  </Link>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default VlanList;
