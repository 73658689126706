import Select from "react-select";
import { db } from "../../utils/firebase_config";
import { collection, getDocs, onSnapshot, query } from "@firebase/firestore";
import { useEffect, useState } from "react";

const customStyles = {
  // option: (provided, state) => ({
  //   ...provided,
  //   borderBottom: "1px dotted pink",
  //   color: state.isSelected ? "red" : "blue",
  //   padding: 20,
  // }),
  // control: () => ({
  //   // none of react-select's styles are passed to <Control />
  //   width: 200,
  // }),
  control: () => ({}),
  // singleValue: (provided, state) => {
  //   const opacity = state.isDisabled ? 0.5 : 1;
  //   const transition = "opacity 300ms";
  //   return { ...provided, opacity, transition };
  // },
};

const DepartmentSelect = ({ onSelectDepartment }) => {
  const collectionRef = collection(db, "department");
  const [departmentList, setDepartmentList] = useState([]);
  useEffect(() => {
    //-----------Vlan list---------
    onSnapshot(collectionRef, (querySnapshot) => {
      const _departmentList = [];
      querySnapshot.forEach((doc) => {
        var data = doc.data();
        var vlanId = data.name;
        vlanId = parseInt(vlanId.substring(4));

        _departmentList.push({
          value: { id: doc.id, ...doc.data() },
          index: vlanId,
          label: doc.data().name,
        });
      });
      var _data = _departmentList;
      _data.sort((a, b) => a.label.localeCompare(b.label));
      console.log(_data);
      setDepartmentList(_data);
    });
  }, []);
  return (
    <div className="">
      <Select
        label="Select Department"
        options={departmentList}
        onChange={(value) => {
          var data = value.value;
          onSelectDepartment(data);
        }}
      />
    </div>
  );
};

export default DepartmentSelect;
