import { Link } from "react-router-dom";
import {
  ArrowsExpandIcon,
  CubeTransparentIcon,
  UserCircleIcon,
  CodeIcon,
  CogIcon,
} from "@heroicons/react/solid";

import SchoolList from "../components/school/school_list_component";
import NewSchool from "../components/school/newSchool_component";

const DepartmentPage = () => {
  return (
    <div>
      <div className="flex flex-row justify-between py-2 px-3  ml-5 mr-5 border-2 bg-blue-200 ">
        <h1>School Page</h1>
        <NewSchool />
      </div>

      <SchoolList />
    </div>
  );
};

export default DepartmentPage;
