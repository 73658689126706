import React from "react";
import { useEffect, useState } from "react";
import { db } from "../utils/firebase_config";

import { doc, getDoc } from "firebase/firestore";
import { useParams } from "react-router";
import NodeList from "../components/node/node_list_component";
import VlanDetailHeader from "../components/vlan/vlan_detail_header";

const VlanDashboardPage = () => {
  const { vlanId } = useParams();

  const [vlan, setVlan] = useState({
    id: 0,
    name: "",
    description: "",
    status: false,
  });

  async function getVlan(vlanid) {
    if (vlanId !== "") {
      const docRef = doc(db, "vlan", vlanId);
      const docSnap = await getDoc(docRef);
      if (docSnap.exists()) {
        console.log("Document data:", docSnap.data());
        const _vlan = {
          id: docSnap.id,
          ...docSnap.data(),
        };
        console.log(_vlan);
        setVlan(_vlan);
      } else {
        // doc.data() will be undefined in this case
        console.log("No such document!");
      }
    }
  }

  useEffect(() => {
    getVlan();
  }, []);
  return vlan.id !== 0 ? (
    <div className="flex flex-col">
      <VlanDetailHeader vlan={vlan} />
      <NodeList vlanId={vlan.id} />
    </div>
  ) : (
    <div>loading</div>
  );
};

export default VlanDashboardPage;
