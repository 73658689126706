import Modal from "react-modal";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { doc, addDoc, updateDoc, deleteDoc } from "firebase/firestore";
import { db } from "../../utils/firebase_config";
import { PencilIcon } from "@heroicons/react/solid";
import Select from "react-select";

const customStyles = {
  content: {
    top: "8%",
    left: "30%",
    right: "30%",
    bottom: "8%",
    // marginRight: "-50%",
    // transform: "translate(-50%, -50%)",
  },
};

Modal.setAppElement("#root");
const EditNode = ({ node }) => {
  const [modalIsOpen, setIsOpen] = useState(false);
  const [_status, setStatus] = useState(node.status);

  function openModal() {
    setIsOpen(true);
  }

  function closeModal() {
    setIsOpen(false);
  }

  //-----------React hook form
  const { register, handleSubmit } = useForm();
  const onSubmit = async (data) => {
    closeModal();

    try {
      const collectionRef = doc(db, "node", node.id);
      console.log(collectionRef.id);
      const payload = { ...data, status: _status };
      console.log(payload);
      await updateDoc(collectionRef, payload);
    } catch (e) {
      console.log("error in edting a new school");
      console.log(e);
    }
  };
  const options = [
    { value: "active", label: "Active" },
    { value: "inActive", label: "InActive" },
  ];
  const _onDelete = async () => {
    try {
      const collectionRef = doc(db, "node", node.id);
      await deleteDoc(collectionRef);
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <div className="whitespace-no-wrap border-b border-gray-200  min-w-max">
      <button onClick={openModal} className="px-9 text-purple-600">
        <PencilIcon className="w-5 " />
      </button>
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        style={customStyles}
        contentLabel="Edit Node"
      >
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className=" flex flex-col justify-between h-80 ">
            <div className="flex flex-row justify-between">
              <div>
                <h1 className="text-lg">Edit Virtual LAN</h1>
              </div>
              <div>
                <button className="bg-red-400" onClick={_onDelete}>
                  Delete
                </button>
                <button className="bg-green-400" type="submit">
                  Save
                </button>
                <button className="bg-purple-400" onClick={closeModal}>
                  close
                </button>
              </div>
            </div>
            <div className="flex flex-col">
              <label className="block text-gray-700">Name</label>
              <input
                type="text"
                className="border-2 border-gray-400 h-8"
                name="ipAddress"
                defaultValue={node.ipAddress}
                {...register("ipAddress", { required: true })}
              />
            </div>
            <div className="flex flex-col">
              <label className="block text-gray-700">Name</label>
              <input
                type="text"
                className="border-2 border-gray-400 h-8"
                name="name"
                defaultValue={node.name}
                {...register("name")}
              />
            </div>
            <div className="flex flex-col">
              <label className="block text-gray-700">Abbreviation</label>
              <input
                type="text"
                className="border-2 border-gray-400 h-8"
                name="abbreviation"
                defaultValue={node.abbreviation}
                {...register("abbreviation", {})}
              />
            </div>
            <div className="flex flex-col ">
              <label className="block text-gray-700">Remarks</label>
              <textarea
                className="border-2 border-gray-400 h-24"
                name="Remarks"
                cols={4}
                {...register("Remarks", {})}
              />
            </div>

            <Select
              options={options}
              defaultValue={_status}
              onChange={(value) => {
                setStatus(value.value);
              }}
            />
          </div>
        </form>
      </Modal>
    </div>
  );
};

export default EditNode;
