import { Link } from "react-router-dom";
import {
  ArrowsExpandIcon,
  CubeTransparentIcon,
  UserCircleIcon,
  CodeIcon,
  CogIcon,
} from "@heroicons/react/solid";
import DepartmentList from "../components/department/department_list_component";
import NewDepartment from "../components/department/newDepartment_component";

const DepartmentPage = () => {
  return (
    <div>
      <div className="flex flex-row justify-between py-2 px-3  ml-5 mr-5 border-2 bg-yellow-200 ">
        <h1>Department Page</h1>
        <NewDepartment />
      </div>
      <DepartmentList />
    </div>
  );
};

export default DepartmentPage;
