import { ViewBoardsIcon, ViewGridIcon } from "@heroicons/react/solid";

import { Link } from "react-router-dom";

const SettingsPage = () => {
  return (
    <div>
      <h1>Settings Page</h1>
      <Link to="/settings/department" className="px-3 py-3">
        <button class="bg-white text-gray-800 font-bold rounded border-b-2 border-red-400 hover:border-red-400 hover:bg-red-400 hover:text-white shadow-md py-2 px-7 inline-flex items-center">
          <div className="flex flex-col px-3 py-3">
            <span class="mr-2">Department</span>
            <ViewBoardsIcon height="50" />
          </div>
        </button>
      </Link>
      <Link to="/settings/school" className="">
        <button class="bg-white text-gray-800 font-bold rounded border-b-2 border-red-400 hover:border-red-400 hover:bg-red-400 hover:text-white shadow-md py-2 px-7 inline-flex items-center">
          <div className="flex flex-col px-3 py-3">
            <span class="mr-2">School</span>
            <ViewGridIcon height="50" />
          </div>
        </button>
      </Link>
    </div>
  );
};

export default SettingsPage;
