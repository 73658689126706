import VlanList from "../components/vlan/vlan_list_component";
import VlanHeader from "../components/vlan/vlan_header_component";

const VlanPage = () => {
  return (
    <div>
      <VlanHeader />
      <VlanList />
    </div>
  );
};

export default VlanPage;
