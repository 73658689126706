import Modal from "react-modal";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import {
  collection,
  addDoc,
  onSnapshot,
  query,
  where,
} from "firebase/firestore";
import { db } from "../../utils/firebase_config";
import { PencilIcon } from "@heroicons/react/solid";
import Select from "react-select";
import DepartmentSelect from "../department/department_select_component";
import VlanSelect from "../vlan/vlan_select_component";
import NumberFormat from "react-number-format";
import HostIdSelect from "./hostId_select_component";

const customStyles = {
  content: {
    top: "8%",
    left: "25%",
    right: "25%",
    bottom: "8%",
    // marginRight: "-50%",
    // transform: "translate(-50%, -50%)",
  },
};

Modal.setAppElement("#root");
const NewNode = () => {
  const [modalIsOpen, setIsOpen] = useState(false);
  const [_status, setStatus] = useState("Active");
  const [department, setDepartment] = useState(0);
  const [vlan, setVlan] = useState();
  const [availableHostId, setAvailableHostId] = useState([]);
  const [hostId, setHostId] = useState([]);
  const [ipAddress, setIpAddress] = useState([]);

  function openModal() {
    setIsOpen(true);
  }

  function closeModal() {
    setIsOpen(false);
  }

  //---------------Department List--------------
  const departmentCollectionRef = collection(db, "department");
  const [departmentList, setDepartmentList] = useState([]);
  //-------------VLAN List--------------
  function settingVlan(selectedVlan) {
    console.log("setting vlan");
    setVlan(selectedVlan);
    if (selectedVlan !== null) {
      settingAvailableHostIdList(selectedVlan);
    }
  }

  const settingAvailableHostIdList = async (vlan) => {
    var _subnet = vlan !== null ? vlan.subnet : 0;

    const q = query(
      collection(db, "node"),
      where("vlan.subnet", "==", _subnet)
    );

    const _availableIpList = [];

    await onSnapshot(q, (querySnapshot) => {
      const _nonAvailableHostId = [];
      querySnapshot.forEach((doc) => {
        const _data = doc.data();
        console.log(doc.data());
        _nonAvailableHostId.push(_data.hostId);
        console.log(_nonAvailableHostId.length);
      });
      for (var i = 0; i < 255; i++) {
        var _alreadyTaken = false;
        for (var j = 0; j < _nonAvailableHostId.length; j++) {
          if (i === _nonAvailableHostId[j]) {
            _alreadyTaken = true;
            break;
          }
        }
        if (_alreadyTaken === false) {
          _availableIpList.push({ value: i, label: i });
        }
      }

      setAvailableHostId(_availableIpList);
    });
  };

  const settingHostId = (value) => {
    const _hostId = value.value;
    setHostId(value.value);
    const _ipAddress = "192.168." + vlan.subnet + "." + _hostId;
    setIpAddress(_ipAddress);
  };

  useEffect(() => {
    onSnapshot(departmentCollectionRef, (querySnapshot) => {
      const department = [];
      querySnapshot.forEach((doc) => {
        department.push({ id: doc.id, ...doc.data() });
      });
      department.sort((a, b) => a.school < b.school);
      setDepartmentList(department);
    });

    //---------------
    // settingAvailableHostIdList();
    console.log(departmentList);
  }, []);

  //-----------React hook form
  const { register, handleSubmit } = useForm();
  const onSubmit = async (data) => {
    closeModal();
    console.log("saving data");
    try {
      const collectionRef = collection(db, "node");

      const payload = {
        ...data,
        department,
        vlan,
        ipAddress,
        hostId: parseInt(hostId.value),
        status: _status,
      };

      await addDoc(collectionRef, payload);
    } catch (e) {
      console.log("error in edting a new node");
      console.log(e);
    }
  };
  const options = [
    { value: "Active", label: "Active" },
    { value: "InActive", label: "InActive" },
  ];

  return (
    <div className=" whitespace-no-wrap border-b h-2/6 bg-red">
      <button
        className="items-center min-h-full min-w-full"
        onClick={openModal}
      >
        <button class="bg-transparent hover:bg-blue-500 text-blue-700 font-semibold hover:text-white py-2 px-4 m-2 border border-blue-500 hover:border-transparent rounded">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="h-6 w-6"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
            strokeWidth={2}
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M9 13h6m-3-3v6m5 5H7a2 2 0 01-2-2V5a2 2 0 012-2h5.586a1 1 0 01.707.293l5.414 5.414a1 1 0 01.293.707V19a2 2 0 01-2 2z"
            />
          </svg>
          <span>New Node</span>
        </button>
      </button>
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        style={customStyles}
        contentLabel="New Node"
      >
        <form onSubmit={handleSubmit(onSubmit)} className=" h-full  ">
          <div className=" flex flex-col justify-between  ">
            <div className="flex flex-row justify-between pb-2">
              <div className="w-1/4 bg-gray-50">
                <h1 className="text-3xl font-semibold">New Node</h1>
              </div>
              <div className="flex flex-row w-1/4 h-8">
                <button
                  className="flex flex-row justify-center items-center bg-green-400 w-2/4  "
                  type="submit"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-6 w-6"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    strokeWidth={2}
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M8 7H5a2 2 0 00-2 2v9a2 2 0 002 2h14a2 2 0 002-2V9a2 2 0 00-2-2h-3m-1 4l-3 3m0 0l-3-3m3 3V4"
                    />
                  </svg>
                  <span className="font-semibold">Save</span>
                </button>

                <button
                  className="flex flex-row justify-center items-center bg-purple-400 w-2/4  "
                  onClick={closeModal}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-5 w-5"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                  >
                    <path
                      fillRule="evenodd"
                      d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                      clipRule="evenodd"
                    />
                  </svg>
                  <span className="font-semibold">Close</span>
                </button>
              </div>
            </div>

            <div className="flex flex-row justify-between  ">
              <div className="flex flex-col w-2/5 mr-3 ">
                <label className="block text-gray-700">Virtual LAN</label>
                <VlanSelect onVlanSelect={settingVlan} />
              </div>
              <div className="flex flex-col justify-between w-2/5 mr-3 ">
                <label className="block text-gray-700">Host Id</label>
                <HostIdSelect
                  onSelect={settingHostId}
                  availableHostId={availableHostId}
                />
              </div>
              <div className="flex flex-col w-1/4 items-center">
                <span>Ip Address</span>
                <span className="font-bold mt-2">{ipAddress}</span>
              </div>
            </div>

            <div className="flex flex-col">
              <label className="block text-gray-700">Name</label>
              <input
                type="text"
                className="border-2 border-gray-400 h-8"
                name="name"
                placeHolder="Name.."
                {...register("name", { required: true })}
              />
            </div>
            <div className="flex flex-col">
              <label className="block text-gray-700">Department</label>
              {department === -1 && "Department is required"}
              <DepartmentSelect
                departmentList={departmentList}
                onSelectDepartment={setDepartment}
              />
            </div>

            <div className="flex flex-col ">
              <label className="block text-gray-700">Remarks</label>
              <textarea
                className="border-2 border-gray-400 h-24"
                name="remarks"
                cols={4}
                {...register("remarks", { required: true })}
              />
            </div>
            <div className="flex flex-col ">
              <label className="block text-gray-700">Status</label>
              <Select
                options={options}
                defaultValue={_status}
                onChange={(value) => {
                  setStatus(value.value);
                }}
              />
            </div>
          </div>
        </form>
      </Modal>
    </div>
  );
};

export default NewNode;
