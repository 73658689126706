import React, { useEffect, useState, useContext } from "react";
import LoginComponent from "./login_component";
import LogOutComponent from "./logout_component";
import { AuthContext } from "../../context/auth_context";

const AuthComponent = () => {
  // const [authToken, setAuthToken] = useState(null);
  const { authToken, setAuthToken } = useContext(AuthContext);
  useEffect(() => {
    let _authToken = sessionStorage.getItem("Auth Token");
    setAuthToken(_authToken);
  }, [authToken]);
  return authToken === null ? <LoginComponent /> : <LogOutComponent />;
};

export default AuthComponent;
