import { db } from "../../utils/firebase_config";
import { collection, getDocs, onSnapshot } from "firebase/firestore";
import { useEffect, useState } from "react";
import EditSchool from "./editSchool_component ";

const SchoolList = () => {
  const collectionRef = collection(db, "school");
  const [schoolList, setSchoolList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  useEffect(() => {
    setIsLoading(true);

    onSnapshot(collectionRef, (querySnapshot) => {
      const school = [];
      querySnapshot.forEach((doc) => {
        school.push({ id: doc.id, ...doc.data() });
      });
      setSchoolList(school);
    });
    // get
    // getDocs(collectionRef).then((snapshot) => {
    //   setSchoolList(
    //     snapshot.docs.map((doc) => {
    //       return {
    //         id: doc.id,
    //         name: doc.data().name,
    //         abbreviation: doc.data().abbreviation,
    //         status: doc.data().status,
    //       };
    //     })
    //   );
    // });
    setIsLoading(false);
  }, []);

  return isLoading ? (
    <div>isLoading...</div>
  ) : (
    <div class="flex flex-col mt-2 ml-5">
      <div class="py-2 -my-2 overflow-x-auto sm:-mx-6 sm:px-6 lg:-mx-8 lg:px-8">
        <div class="inline-block min-w-full overflow-hidden align-middle border-b border-gray-200 shadow sm:rounded-lg">
          <table class="min-w-full">
            <thead className="bg-gray-500">
              <tr>
                <th class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50">
                  Name
                </th>
                <th class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50">
                  Abbreviation
                </th>
                <th class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50">
                  Edit
                </th>
                <th class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50">
                  Status
                </th>
              </tr>
            </thead>

            <tbody class="bg-white">
              {schoolList.map((school) => (
                <tr>
                  <td class="px-6 py-4 whitespace-no-wrap border-b border-gray-200">
                    <div class="flex items-start">
                      <div class="">
                        <div class="text-sm font-medium leading-5 text-gray-900">
                          {school.name}
                        </div>
                      </div>
                    </div>
                  </td>
                  <td class="px-6 py-4 whitespace-no-wrap border-b border-gray-200">
                    <div class="text-sm leading-5 text-gray-500">
                      {school.abbreviation}
                    </div>
                  </td>
                  <EditSchool
                    documentId={school.id}
                    name={school.name}
                    abbreviation={school.abbreviation}
                    status={school.status}
                  />

                  <td class="px-6 py-4 whitespace-no-wrap border-b border-gray-200">
                    {school.status === "Active" ? (
                      <span class="inline-flex px-2 text-xs font-semibold leading-5 text-green-800 bg-green-100 rounded-full">
                        Active
                      </span>
                    ) : (
                      <span class="inline-flex px-2 text-xs font-semibold leading-5 text-green-800 bg-red-100 rounded-full">
                        InActive
                      </span>
                    )}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default SchoolList;
