import Modal from "react-modal";
import { useContext, useState } from "react";
import { AuthContext } from "../../context/auth_context";

import { UserCircleIcon } from "@heroicons/react/outline";
import "react-toastify/dist/ReactToastify.css";

const customStyles = {
  content: {
    top: "30%",
    left: "30%",
    right: "30%",
    bottom: "30%",
    // marginRight: "-50%",
    // transform: "translate(-50%, -50%)",
  },
};

Modal.setAppElement("#root");
const LogOutComponent = () => {
  const [modalIsOpen, setIsOpen] = useState(false);
  const { setAuthToken } = useContext(AuthContext);

  function openModal() {
    setIsOpen(true);
  }

  function closeModal() {
    setIsOpen(false);
  }

  function logout() {
    sessionStorage.removeItem("Auth Token");
    setAuthToken(null);
    closeModal();
  }

  return (
    <div className=" whitespace-no-wrap border-b h-2/6 bg-red">
      <button
        className="items-center min-h-full min-w-full"
        onClick={openModal}
      >
        <button class="bg-transparent hover:bg-blue-500 text-blue-700 font-semibold hover:text-white  m-2 border border-blue-500 hover:border-transparent rounded">
          <UserCircleIcon />
          <span> "Logout"</span>
        </button>
      </button>
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        style={customStyles}
        contentLabel="New Node"
      >
        <div className=" flex flex-col justify-between items-center  h-full  ">
          <div className="pt-16">
            <span className="text-lg">Confirm Logout</span>
          </div>
          <div className="flex flex-row justify-between h-2/6 pb-2 min-w-max">
            <div className="flex flex-row min-w-max bg-black ">
              <button
                className="flex flex-row justify-center items-center bg-green-400 w-3/4  "
                onClick={logout}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-6 w-6"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  strokeWidth={2}
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M8 7H5a2 2 0 00-2 2v9a2 2 0 002 2h14a2 2 0 002-2V9a2 2 0 00-2-2h-3m-1 4l-3 3m0 0l-3-3m3 3V4"
                  />
                </svg>
                <span className="font-semibold">LogOut</span>
              </button>

              <button
                className="flex flex-row justify-center items-center bg-purple-400 w-3/4  "
                onClick={closeModal}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-5 w-5"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                >
                  <path
                    fillRule="evenodd"
                    d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                    clipRule="evenodd"
                  />
                </svg>
                <span className="font-semibold">Close</span>
              </button>
            </div>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default LogOutComponent;
