import Select from "react-select";
import { db } from "../../utils/firebase_config";
import { collection, getDocs, onSnapshot, query } from "@firebase/firestore";
import { useEffect, useState } from "react";

const NodeSelect = ({ onSelect }) => {
  const [nodeList, setNodeList] = useState([]);
  const collectionRef = collection(db, "node");

  useEffect(() => {
    const q = query(collection(db, "node"));
    onSnapshot(q, (querySnapshot) => {
      const nodes = [];
      querySnapshot.forEach((doc) => {
        nodes.push({ id: doc.id, label: doc.data().name });
      });
      var _data = nodes;
      _data.sort((a, b) => a.label.localeCompare(b.label));
      console.log(_data);
      setNodeList(_data);
    });
  }, []);
  return (
    <div className="">
      <Select
        options={nodeList}
        onChange={(value) => {
          console.log(value);
          onSelect(value);
        }}
      />
    </div>
  );
};

export default NodeSelect;
