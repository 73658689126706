
import Search from "./search_component";
import {
  ArrowsExpandIcon,
  CubeTransparentIcon,
  CogIcon,
} from "@heroicons/react/solid";
import AuthComponent from '../components/login/auth_component';

import { Link } from "react-router-dom";

const Header = () => {


  return (
    <div className="flex flex-row justify-between items-center m-4">
      <div className="flex flex-col">
        <span className="text-lg">Mizoram University Network Directory</span>
        <span className="text-sm">
          Developed and Maintain by ICT Centre, 
          Mizoram University
        </span>
      </div>

     
      <div className="flex flex-row  justify-between px-5 ">
        <Link to="/" className="px-3">
          <button class="bg-white text-gray-800 font-bold rounded border-b-2 border-green-500 hover:border-green-600 hover:bg-green-500 hover:text-white shadow-md py-2 px-9 inline-flex items-center">
            <div className="flex flex-col">
              <span class="mr-2">VLAN</span>
              <ArrowsExpandIcon height="20" />
            </div>
          </button>
        </Link>

        <Link to="/node" className="px-3">
          <button class="bg-white text-gray-800 font-bold rounded border-b-2 border-purple-500 hover:border-green-600 hover:bg-purple-500 hover:text-white shadow-md py-2 px-7 inline-flex items-center">
            <div className="flex flex-col">
              <span class="mr-2">NODE</span>
              <CubeTransparentIcon height="20" />
            </div>
          </button>
        </Link>
        <Link to="/department" className="px-3">
          <button class="bg-white text-gray-800 font-bold rounded border-b-2 border-yellow-400 hover:border-red-400 hover:bg-red-400 hover:text-white shadow-md py-2 px-7 inline-flex items-center">
            <div className="flex flex-col">
              <span class="mr-2">Department</span>
              <CogIcon height="20" />
            </div>
          </button>
        </Link>
         <Link to="/school" className="px-3">
          <button class="bg-white text-gray-800 font-bold rounded border-b-2 border-blue-400 hover:border-red-400 hover:bg-red-400 hover:text-white shadow-md py-2 px-7 inline-flex items-center">
            <div className="flex flex-col">
              <span class="mr-2">School</span>
              <CogIcon height="20" />
            </div>
          </button>
        </Link>

        <Link to="/settings" className="px-3">
          <button class="bg-white text-gray-800 font-bold rounded border-b-2 border-red-400 hover:border-red-400 hover:bg-red-400 hover:text-white shadow-md py-2 px-7 inline-flex items-center">
            <div className="flex flex-col">
              <span class="mr-2">Settings</span>
              <CogIcon height="20" />
            </div>
          </button>
        </Link>
      </div>
      <div>
        <AuthComponent/>
      </div>
      
    </div>
  );
};

export default Header;
