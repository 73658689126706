import Header from "../components/header_component";
import { Routes, Route } from "react-router-dom";

import SettingsPage from "./settings_page";
import DepartmentPage from "./department_page";
import SchoolPage from "./school_page";
import VlanPage from "./vlan_page";
import NodePage from "./node_page";
import VlanDashboardPage from "./vlan_dashboard_page";

const HomePage = () => {
  return (
    <div className="flex flex-col">
      <Header />

      <Routes>
        <Route path="/" element={<VlanPage />} />
        <Route path="/vlan/:vlanId" element={<VlanDashboardPage />} />
        <Route path="/node" element={<NodePage />} />
        <Route path="/settings" element={<SettingsPage />} />
        <Route path="/department" element={<DepartmentPage />} />
        <Route path="/school" element={<SchoolPage />} />
      </Routes>
    </div>
  );
};

export default HomePage;
