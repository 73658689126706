import Modal from "react-modal";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { doc, addDoc, updateDoc } from "firebase/firestore";
import { db } from "../../utils/firebase_config";
import { PencilIcon } from "@heroicons/react/solid";
import Select from "react-select";
import SchoolSelect from "../school/school_select_component";

const customStyles = {
  content: {
    top: "15%",
    left: "30%",
    right: "30%",
    bottom: "15%",
    // marginRight: "-50%",
    // transform: "translate(-50%, -50%)",
  },
};

Modal.setAppElement("#root");
const EditDepartment = ({ documentId, name, abbreviation, school, status }) => {
  const [modalIsOpen, setIsOpen] = useState(false);
  const [_status, setStatus] = useState(status);
  const [_school, setSchool] = useState(school);

  function openModal() {
    setIsOpen(true);
  }

  function closeModal() {
    setIsOpen(false);
  }

  //-----------React hook form
  const { register, handleSubmit } = useForm();
  const onSubmit = async (data) => {
    closeModal();

    try {
      console.log(typeof documentId);
      console.log(documentId);

      const collectionRef = doc(db, "department", documentId);
      console.log(collectionRef.id);
      const payload = { ...data, school: _school, status: _status };
      console.log(payload);
      await updateDoc(collectionRef, payload);
    } catch (e) {
      console.log("error in edting a new school");
      console.log(e);
    }
  };
  const options = [
    { value: "Active", label: "Active" },
    { value: "InActive", label: "InActive" },
  ];

  return (
    <div>
      <button className="" onClick={openModal}>
        <PencilIcon height="20" color="" />
      </button>
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        style={customStyles}
        contentLabel="Edit Node"
      >
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className=" flex flex-col justify-between  h-64">
            <div className="flex flex-row justify-between">
              <div>
                <h1 className="text-lg">New Department</h1>
              </div>
              <div>
                <button className="bg-green-400" type="submit">
                  Save
                </button>
                <button className="bg-purple-400" onClick={closeModal}>
                  close
                </button>
              </div>
            </div>
            <div className="flex flex-col">
              <label className="block text-gray-700">Name</label>
              <input
                type="text"
                className="border-2 border-gray-400 h-8"
                name="name"
                defaultValue={name}
                {...register("name", { required: true })}
              />
            </div>
            <div className="flex flex-col">
              <label className="block text-gray-700">Abbreviation</label>
              <input
                type="text"
                className="border-2 border-gray-400 h-8"
                name="abbreviation"
                defaultValue={abbreviation}
                {...register("abbreviation", { required: true })}
              />
            </div>
            <SchoolSelect setSchool={setSchool} />
            <Select
              options={options}
              defaultValue={_status}
              onChange={(value) => {
                setStatus(value.value);
              }}
            />
          </div>
        </form>
      </Modal>
    </div>
  );
};

export default EditDepartment;
