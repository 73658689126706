import React from "react";

import NodeList from "../components/node/node_list_component";

const UserHomePage = () => {
  return (
    <div>
      <NodeList />
    </div>
  );
};

export default UserHomePage;
