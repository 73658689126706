import NewNode from "./newNode_component";
import Search from "../search_component";
import { AuthContext } from "../../context/auth_context";
import { useContext } from "react";
import LoginComponent from "../login/login_component";

const NodeHeader = ({ onFilterReset }) => {
  const { authToken} = useContext(AuthContext);

  return (
    <div className="flex flex-row  justify-between items-center px-3  ml-5 mr-5 border-2 border-purple-400 ">
      {authToken != null ? (
        <h1 className="xl:sticky text-2xl ">Node Page</h1>
      ) : (
        <div>
          <h1 className="font-medium text-2xl">
            Mizoram University Network Manager
          </h1>
          <h2>Developed and maintained by ICT Centre, MZU</h2>
        </div>
      )}

      {/* <Search /> */}
      <div className="flex flex-row">
        <div className="flex flex-row">
          <button
            class=" m-2 bg-transparent
           hover:bg-purple-500 text-purple-700 font-semibold hover:text-white py-2 px-4 border
            border-purple-500 hover:border-transparent rounded"
            onClick={onFilterReset}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-6 w-6"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
              strokeWidth={2}
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M14 10l-2 1m0 0l-2-1m2 1v2.5M20 7l-2 1m2-1l-2-1m2 1v2.5M14 4l-2-1-2 1M4 7l2-1M4 7l2 1M4 7v2.5M12 21l-2-1m2 1l2-1m-2 1v-2.5M6 18l-2-1v-2.5M18 18l2-1v-2.5"
              />
            </svg>
            <span>Reset Filter</span>
          </button>
          {authToken == null ? <LoginComponent /> : <NewNode />}
          {/* {authToken !== null ? <NewNode /> : <></>} */}
        </div>
      </div>
    </div>
  );
};

export default NodeHeader;
