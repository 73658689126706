import Select from "react-select";
import { db } from "../../utils/firebase_config";
import { collection, getDocs, query, onSnapshot } from "@firebase/firestore";
import { useEffect, useState } from "react";

const IpSelect = ({ onSelect }) => {
  const [nodeList, setNodeList] = useState([]);

  useEffect(() => {
    const q = query(collection(db, "node"));
    onSnapshot(q, (querySnapshot) => {
      const nodes = [];
      querySnapshot.forEach((doc) => {
        nodes.push({ id: doc.id, label: doc.data().ipAddress });
      });
      setNodeList(nodes);
    });
  }, []);
  return (
    <div className="">
      <Select
        options={nodeList}
        onChange={(value) => {
          console.log(value);
          onSelect(value);
        }}
      />
    </div>
  );
};

export default IpSelect;
