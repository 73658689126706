import Modal from "react-modal";
import { useEffect, useState, useContext } from "react";
import { AuthContext } from "../../context/auth_context";
import { useForm } from "react-hook-form";
import { auth } from "../../utils/firebase_config";

import { UserCircleIcon } from "@heroicons/react/outline";
import { ToastContainer, toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";
import { signInWithEmailAndPassword } from "firebase/auth";

const customStyles = {
  content: {
    top: "30%",
    left: "40%",
    right: "40%",
    bottom: "50%",
    // marginRight: "-50%",
    // transform: "translate(-50%, -50%)",
  },
};

Modal.setAppElement("#root");
const LoginComponent = () => {
  const [modalIsOpen, setIsOpen] = useState(false);

  const { setAuthToken } = useContext(AuthContext);

  const navigate = useNavigate();

  function openModal() {
    setIsOpen(true);
  }

  function closeModal() {
    setIsOpen(false);
  }

  function signOut() {
    sessionStorage.removeItem("Auth Token");
  }

  //-----------React hook form
  const { register, handleSubmit } = useForm();
  const onSubmit = (data) => {
    console.log("saving data");
    if (data === null) {
      return;
    }

    const _email = data.email;
    const _password = data.passWord;

    // const _email = "admin@ict.mzu.com";
    // const _password = "123456";

    if (_email !== "admin@ict.mzu.com") {
      console.log("incorrect email");
    }
    if (_password !== "123456") {
      console.log("incorrect password");
    }
    if ((_email === null) & (_password === null)) {
      return;
    }
    signInWithEmailAndPassword(auth, _email, _password)
      // signInWithEmailAndPassword(auth, _email, _password)
      .then((response) => {
        toast.done("Signin Completed");
        setAuthToken(response._tokenResponse.refreshToken);
        sessionStorage.setItem(
          "Auth Token",
          response._tokenResponse.refreshToken
        );
        closeModal();
      })
      .catch((error) => {
        if (error.code === "auth/invalid-email") {
          toast.error("Invalid Email Id");
        }
        if (error.code === "auth/missing-email") {
          toast.error("Missing Email Field");
        }
        if (error.code === "auth/wrong-password") {
          toast.error("Wrong Password");
        }
      });
  };

  return (
    <div className=" whitespace-no-wrap border-b h-2/6 bg-red">
      <button
        className="items-center min-h-full min-w-full"
        onClick={openModal}
      >
        <button class="bg-transparent hover:bg-blue-500 text-blue-700 font-semibold hover:text-white  m-2 border border-blue-500 hover:border-transparent rounded">
          <UserCircleIcon />
          <span>Login</span>
        </button>
      </button>
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        style={customStyles}
        contentLabel="New Node"
      >
        <form onSubmit={handleSubmit(onSubmit)} className=" h-full  ">
          <div className=" flex flex-col justify-between h-full  ">
            <div className="flex flex-row justify-between pb-2">
              <div className="w-2/4 bg-gray-50">
                <h1 className="text-3xl font-semibold">Login</h1>
              </div>
              <div className="flex flex-row w-1/4 h-8">
                <button
                  className="flex flex-row justify-center items-center bg-green-400 w-2/4  "
                  type="submit"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-6 w-6"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    strokeWidth={2}
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M8 7H5a2 2 0 00-2 2v9a2 2 0 002 2h14a2 2 0 002-2V9a2 2 0 00-2-2h-3m-1 4l-3 3m0 0l-3-3m3 3V4"
                    />
                  </svg>
                  <span className="font-semibold">Login</span>
                </button>

                <button
                  className="flex flex-row justify-center items-center bg-purple-400 w-2/4  "
                  onClick={closeModal}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-5 w-5"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                  >
                    <path
                      fillRule="evenodd"
                      d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                      clipRule="evenodd"
                    />
                  </svg>
                  <span className="font-semibold">Close</span>
                </button>
              </div>
            </div>
            <div className="flex flex-col justify-between  h-5/6">
              <div className="flex flex-col">
                <label className="block text-gray-700">Email</label>
                <input
                  type="text"
                  className="border-2 border-gray-400 h-8"
                  name="email"
                  placeHolder="email.."
                  {...register("email", { required: true })}
                />
              </div>
              <div className="flex flex-col">
                <label className="block text-gray-700">Password</label>
                <input
                  type="password"
                  className="border-2 border-gray-400 h-8"
                  name="passWord"
                  placeholder="password.."
                  {...register("passWord", { required: true })}
                />
              </div>
            </div>
            <ToastContainer></ToastContainer>
          </div>
        </form>
      </Modal>
    </div>
  );
};

export default LoginComponent;
