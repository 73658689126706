import React from "react";
import { useState } from "react";

const VlanDetailHeader = ({ vlan }) => {
  const [edit, setEdit] = useState();
  return (
    <div className="flex flex-row justify-between py-2 px-3 items-center ml-5 mr-5 border-2 bg-green-100 ">
      <div className="flex flex-row w-4/6 ml-5 mr-5 mt-1 items-center justify-between ">
        <div className="flex flex-row ">
          <span className="mr-2">Name:</span>
          <h1>{vlan.name}</h1>
        </div>
        <div className="flex flex-row w-3/6 py-2 px-3">
          <span className="mr-2">IP Range:</span>
          {edit ? (
            <input
              className="shadow appearance-none border 
                  rounded w-4/6 py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              id="username"
              type="text"
              placeholder={vlan.ipRange}
            />
          ) : (
            <h1 className="">{vlan.ipRange}</h1>
          )}
        </div>
        <div className="flex flex-row">
          <span className="mr-2">Description:</span>
          <h1>{vlan.description}</h1>
        </div>
        <div className="flex flex-row">
          <span className="mr-2">Status:</span>
          <h1>{vlan.status}</h1>
        </div>
      </div>
      <div
        className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
        onClick={() => {
          setEdit(!edit);
        }}
      >
        <button>Edit</button>
      </div>
    </div>
  );
};

export default VlanDetailHeader;
