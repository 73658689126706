import NodeHeader from "../components/node/node_header_component";
import NodeList from "../components/node/node_list_component";

const NodePage = () => {
  return (
    <div>
      {/* <NodeHeader /> */}
      <NodeList />
    </div>
  );
};

export default NodePage;
