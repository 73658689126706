import HomePage from "./page/home_page";
import { AuthContext } from "./context/auth_context";
import { useState } from "react";
import UserHomePage from "./page/user_home_page";

function App() {
  const [authToken, setAuthToken] = useState();
  const [nodeList, setNodeList] = useState();
  return (
    <div className="App">
      <AuthContext.Provider
        value={{ authToken, setAuthToken, nodeList, setNodeList }}
      >
        {authToken != null ? (
          <header className="App-header">
            <HomePage />
          </header>
        ) : (
          <UserHomePage />
        )}
      </AuthContext.Provider>
    </div>
  );
}

export default App;
