import { db } from "../../utils/firebase_config";
import { collection, getDocs, onSnapshot } from "firebase/firestore";
import { useEffect, useState } from "react";
import EditDepartment from "./editDepartment_component ";
import {
  CollectionIcon,
  PencilAltIcon,
  PencilIcon,
} from "@heroicons/react/solid";

const DepartmentList = () => {
  const collectionRef = collection(db, "department");
  const [departmentList, setDepartmentList] = useState([]);
  useEffect(() => {
    onSnapshot(collectionRef, (querySnapshot) => {
      const department = [];
      querySnapshot.forEach((doc) => {
        department.push({ id: doc.id, ...doc.data() });
      });
      department.sort((a, b) => a.name < b.school);
      setDepartmentList(department);
    });

    console.log(departmentList);
  }, []);

  return (
    <div class="flex flex-col mt-2 ml-5">
      <div class="py-2 -my-2 overflow-x-auto sm:-mx-6 sm:px-6 lg:-mx-8 lg:px-8">
        <div class="inline-block min-w-full overflow-hidden align-middle border-b border-gray-200 shadow sm:rounded-lg">
          <table class="min-w-full">
            <thead className="bg-gray-500">
              <tr>
                <th class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50">
                  Name
                </th>
                <th class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50">
                  Abbreviation
                </th>
                <th class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50">
                  School/Settings
                </th>
                <th class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50">
                  Edit
                </th>
                <th class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50">
                  Status
                </th>
              </tr>
            </thead>

            <tbody class="bg-white">
              {departmentList.map((department) => (
                <tr>
                  <td class="px-6 py-4 whitespace-no-wrap border-b border-gray-200">
                    <div class="flex items-start">
                      <div class="">
                        <div class="text-sm font-medium leading-5 text-gray-900">
                          {department.name}
                        </div>
                      </div>
                    </div>
                  </td>
                  <td class="px-6 py-4 whitespace-no-wrap border-b border-gray-200">
                    <div class="text-sm leading-5 text-gray-500">
                      {department.abbreviation}
                    </div>
                  </td>
                  <td class="px-6 py-4 whitespace-no-wrap border-b border-gray-200">
                    <div class="text-sm leading-5 text-gray-500">
                      {department.school.name}
                    </div>
                  </td>
                  <EditDepartment
                    documentId={department.id}
                    name={department.name}
                    school={department.school}
                    abbreviation={department.abbreviation}
                    status={department.status}
                  />

                  <td class="px-6 py-4 whitespace-no-wrap border-b border-gray-200">
                    {department.status === "Active" ? (
                      <span class="inline-flex px-2 text-xs font-semibold leading-5 text-green-800 bg-green-100 rounded-full">
                        Active
                      </span>
                    ) : (
                      <span class="inline-flex px-2 text-xs font-semibold leading-5 text-green-800 bg-red-100 rounded-full">
                        InActive
                      </span>
                    )}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default DepartmentList;
