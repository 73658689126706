import Select from "react-select";
import { db } from "../../utils/firebase_config";
import { collection, query, onSnapshot, where } from "@firebase/firestore";
import { useEffect, useState } from "react";

const HostIdSelect = ({ onSelect, availableHostId = [] }) => {
  return (
    <Select
      className=""
      options={availableHostId}
      onChange={(value) => {
        console.log(value);
        onSelect(value);
      }}
    />
  );
};

export default HostIdSelect;
