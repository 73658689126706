import EditNode from "./editNode_component";
import NodeSelect from "./node_select_component";
import DepartmentSelect from "../department/department_select_component";
import VlanSelect from "../vlan/vlan_select_component";
import IpSelect from "./ipaddress_select_component";
import SchoolSelect from "../school/school_select_component";
import { useContext } from "react";
import { AuthContext } from "../../context/auth_context";

const NodeListTable = ({
  nodeList = [],
  departmentList,
  onSelectDepartment,
  onNodeSelect,
  onIpSelect,
  onVlanSelect,
  onSchoolSelect,
}) => {
  console.log("inside node table list");
  console.log(nodeList);

  const { authToken } = useContext(AuthContext);

  var _blankRow = (
    <tr>
      <td class="px-6 py-4 whitespace-no-wrap border-b border-gray-200" />

      <td class="px-6 py-4 whitespace-no-wrap border-b border-gray-200" />

      <td class="px-6 py-4 whitespace-no-wrap border-b border-gray-200" />

      <td class="px-6 py-4 whitespace-no-wrap border-b border-gray-200" />

      <td class="px-6 py-4 whitespace-no-wrap border-b border-gray-200" />

      <td class="px-6 py-4 whitespace-no-wrap border-b border-gray-200" />

      <td class="px-6 py-4 whitespace-no-wrap border-b border-gray-200" />
    </tr>
  );
  var _blankRows = [
    _blankRow,
    _blankRow,
    _blankRow,
    _blankRow,
    _blankRow,
    _blankRow,
    _blankRow,
    _blankRow,
  ];
  if (nodeList.length < 10) {
    console.log("generating blankrow");
    var _blankLength = 10 - nodeList.length;
    for (var i = _blankLength; i < 10; i++) {
      console.log("generating blankrow");
      _blankRows.concat();
    }
  }
  console.log(_blankRows);

  return (
    <div class="flex flex-col mt-2 ml-5 mr-5 pt-5 ">
      <div class="inline-block min-w-full overflow-hidden align-middle border-b border-gray-200 shadow sm:rounded-lg">
        <table class="min-w-full  ">
          <thead class="min-w-full  flex flex-col ml-2">
            <tr className="flex flex-row items-center justify-between border-gray-200 bg-gray-50 h-22">
              <th
                class=" w-2/12  text-xs font-medium leading-4
                tracking-wider text-left  uppercase border-b border-gray-200 bg-red-600"
              >
                <span className="text-white">Ip Address</span>

                <IpSelect onSelect={onIpSelect} />
              </th>
              <th class="w-3/12 text-xs font-medium leading-4 tracking-wider text-left  uppercase border-b border-gray-200 bg-blue-500">
                <span className="text-white">Name</span>
                <NodeSelect onSelect={onNodeSelect} className="ml-2 mr-2" />
              </th>

              <th class="w-3/12 text-xs font-medium leading-4 tracking-wider text-left uppercase border-b border-gray-200 bg-yellow-500">
                <span className="text-white">Department</span>
                <DepartmentSelect
                  departmentList={departmentList}
                  onSelectDepartment={onSelectDepartment}
                />
              </th>
              {/* <th class="w-  text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-red-500">
                School */}
              {/* <SchoolSelect setSchool={onSchoolSelect} /> */}
              {/* </th> */}
              <th class="w-2/12  text-xs font-medium leading-4 tracking-wider text-left  uppercase border-b border-gray-200 bg-green-500">
                <span className="text-white">Vlan</span>
                <VlanSelect
                  vlanList={departmentList}
                  onVlanSelect={onVlanSelect}
                />
              </th>

              {authToken ? (
                <th class=" flex flex-col w-24 px-1 py-5  items-center  text-xs font-medium leading-4 tracking-wider text-left  uppercase border-b border-gray-200 bg-purple-500">
                  <span className="text-white">Edit</span>
                </th>
              ) : (
                <div></div>
              )}

              <th class="flex flex-col w-24 py-5 mr-5 text-xs items-center font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-red-500">
                <span className="text-white">Status</span>
              </th>
            </tr>
          </thead>

          <tbody className="min-w-full  flex flex-col ml-2">
            {nodeList.length !== 0 ? (
              nodeList.map((node) => (
                <tr className="flex flex-row items-center justify-between border-gray-200 bg-gray-50 h-22">
                  <th
                    class=" w-2/12  text-xs font-medium leading-4
                tracking-wider text-left  uppercase border-b border-gray-200 "
                  >
                    <span className="">{node.ipAddress}</span>
                  </th>
                  <th class="w-3/12 text-xs font-medium leading-4 tracking-wider text-left  uppercase border-b border-gray-200 ">
                    {node.name}
                  </th>

                  <th class="w-3/12 text-xs font-medium leading-4 tracking-wider text-left uppercase border-b border-gray-200 ">
                    {node.department.name}
                  </th>

                  <th class="w-2/12  text-xs font-medium leading-4 tracking-wider text-left  uppercase border-b border-gray-200 ">
                    {node.vlan.name}
                  </th>

                  {authToken ? (
                    <th class=" flex flex-col w-24 px-1 py-5  items-center  text-xs font-medium leading-4 tracking-wider text-left  uppercase border-b border-gray-200 ">
                      <EditNode node={node} />
                    </th>
                  ) : (
                    <div></div>
                  )}

                  <th class="flex flex-col w-24 py-5 mr-5 text-xs items-center font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 ">
                    {node.status === "Active" ? (
                      <span class="inline-flex px-2 text-xs font-semibold leading-5  rounded-full">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          className="h-6 w-6 text-green-500"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke="currentColor"
                          strokeWidth={2}
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z"
                          />
                        </svg>
                      </span>
                    ) : (
                      <span>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          className="h-6 w-6 text-red-500"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke="currentColor"
                          strokeWidth={2}
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="M12 8v4m0 4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                            className="fill-cyan-500 hover:fill-cyan-700"
                          />
                        </svg>
                      </span>
                    )}
                  </th>
                </tr>

                // <tr className="flex flex-row items-center justify-between border-gray-200 bg-gray-50 h-7">
                //   <td class="w-28 ml  whitespace-no-wrap  border-b border-gray-200  text-sm font-medium leading-5 text-gray-900  bg-red-500">
                //     {/* <span className="">{node.ipAddress}</span> */}
                //   </td>
                //   <td class="w-3/12   whitespace-no-wrap border-b border-gray-200  text-sm font-medium leading-5 text-gray-900  bg-blue-500">
                //     {/* <span className="">{node.name}</span> */}
                //   </td>
                //   <td class="w-3/12   whitespace-no-wrap border-b border-gray-200  text-sm font-medium leading-5 text-gray-900 ">
                //     {/* <span className="">{node.department.name}</span> */}
                //   </td>
                //   <td class="w-3/12   whitespace-no-wrap border-b border-gray-200  text-sm font-medium leading-5 text-gray-900 ">
                //     {/* <span className="">{node.department.school.name}</span> */}
                //   </td>
                //   <td class="w-36   whitespace-no-wrap border-b border-gray-200  text-sm font-medium leading-5 text-gray-900 ">
                //     {/* <span className="">{node.vlan.name}</span> */}
                //   </td>
                //   {authToken ? (
                //     <div className="w-24  whitespace-no-wrap border-b border-gray-200">
                //       {/* <EditNode node={node} /> */}
                //     </div>
                //   ) : (
                //     <div></div>
                //   )}

                //   <td class="w-24  whitespace-no-wrap border-b border-gray-200">
                //     {node.status === "Active" ? (
                //       <span class="inline-flex px-2 text-xs font-semibold leading-5  rounded-full">
                //         <svg
                //           xmlns="http://www.w3.org/2000/svg"
                //           className="h-6 w-6 text-green-500"
                //           fill="none"
                //           viewBox="0 0 24 24"
                //           stroke="currentColor"
                //           strokeWidth={2}
                //         >
                //           <path
                //             strokeLinecap="round"
                //             strokeLinejoin="round"
                //             d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z"
                //           />
                //         </svg>
                //       </span>
                //     ) : (
                //       <span>
                //         <svg
                //           xmlns="http://www.w3.org/2000/svg"
                //           className="h-6 w-6 text-red-500"
                //           fill="none"
                //           viewBox="0 0 24 24"
                //           stroke="currentColor"
                //           strokeWidth={2}
                //         >
                //           <path
                //             strokeLinecap="round"
                //             strokeLinejoin="round"
                //             d="M12 8v4m0 4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                //             className="fill-cyan-500 hover:fill-cyan-700"
                //           />
                //         </svg>
                //       </span>
                //     )}
                //   </td>
                // </tr>
              ))
            ) : (
              <div></div>
            )}
            {_blankRows.map((e) => e)}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default NodeListTable;
