import Modal from "react-modal";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { collection, addDoc } from "firebase/firestore";
import { db } from "../../utils/firebase_config";
import { PencilIcon } from "@heroicons/react/solid";
import Select from "react-select";

const customStyles = {
  content: {
    top: "8%",
    left: "30%",
    right: "30%",
    bottom: "20%",
    // marginRight: "-50%",
    // transform: "translate(-50%, -50%)",
  },
};

Modal.setAppElement("#root");
const EditVlan = () => {
  const [modalIsOpen, setIsOpen] = useState(false);
  const [_status, setStatus] = useState("Active");

  function openModal() {
    setIsOpen(true);
  }

  function closeModal() {
    setIsOpen(false);
  }

  //-----------React hook form
  const { register, handleSubmit } = useForm();
  const onSubmit = async (data) => {
    closeModal();

    try {
      const collectionRef = collection(db, "vlan");
      console.log(collectionRef.id);
      const payload = { ...data, status: _status };
      console.log(payload);
      await addDoc(collectionRef, payload);
    } catch (e) {
      console.log("error in edting a new Vlan");
      console.log(e);
    }
  };
  const options = [
    { value: "active", label: "Active" },
    { value: "inActive", label: "InActive" },
  ];

  return (
    <div className="max-h-full">
      <button
        onClick={openModal}
        class="bg-transparent hover:bg-blue-500 text-blue-700 font-semibold hover:text-white py-2 px-4 border border-blue-500 hover:border-transparent rounded"
      >
        New Vlan
      </button>
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        style={customStyles}
        contentLabel="Edit Node"
      >
        <form
          onSubmit={handleSubmit(onSubmit)}
          className="flex flex-col justify-between max-h-full "
        >
          <div className="flex flex-row justify-between ">
            <div>
              <h1 className="text-lg">New Virtual LAN</h1>
            </div>
            <div className="flex flex-row justify-between w-20 ">
              <button className="bg-green-400" type="submit">
                Save
              </button>
              <button className="bg-purple-400" onClick={closeModal}>
                close
              </button>
            </div>
          </div>
          <div className="flex flex-col">
            <label className="block text-gray-700">Name</label>
            <input
              type="text"
              className="border-2 border-gray-400 h-8"
              name="name"
              placeHolder="Name.."
              {...register("name", { required: true })}
            />
          </div>
          <div className="flex flex-col">
            <label className="block text-gray-700">Ip Range</label>
            <input
              type="text"
              className="border-2 border-gray-400 h-8"
              name="subnet"
              placeHolder="Subnet"
              {...register("subnet", { required: true })}
            />
          </div>

          <div className="flex flex-col ">
            <label className="block text-gray-700">Description</label>
            <textarea
              className="border-2 border-gray-400 h-24"
              name="description"
              cols={4}
              {...register("description", { required: true })}
            />
          </div>

          <Select
            options={options}
            defaultValue={_status}
            onChange={(value) => {
              setStatus(value.value);
            }}
          />
        </form>
      </Modal>
    </div>
  );
};

export default EditVlan;
