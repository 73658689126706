import Select from "react-select";
import { db } from "../../utils/firebase_config";
import { collection, onSnapshot } from "@firebase/firestore";
import { useEffect, useState } from "react";

const VlanSelect = ({ onVlanSelect }) => {
  const vlanCollectionRef = collection(db, "vlan");
  const [vlanList, setVlanList] = useState([]);

  useEffect(() => {
    //-----------Vlan list---------
    onSnapshot(vlanCollectionRef, (querySnapshot) => {
      const _vlanList = [];
      querySnapshot.forEach((doc) => {
        var data = doc.data();
        var vlanId = data.name;
        vlanId = parseInt(vlanId.substring(4));

        _vlanList.push({
          value: { id: doc.id, ...doc.data() },
          index: vlanId,
          label: doc.data().name,
        });
      });
      var _data = _vlanList;
      _data.sort((a, b) => a.label.localeCompare(b.label));
      console.log(_data);
      setVlanList(_data);
    });
  }, []);
  return (
    <div className="">
      {vlanList.length !== 0 ? (
        <Select
          options={vlanList}
          onChange={(value) => {
            console.log("Inside Vlan Select-The seleted Vlan Value is ");
            console.log(value);
            var data = value.value;
            onVlanSelect(data);
          }}
        />
      ) : (
        <></>
      )}
    </div>
  );
};

export default VlanSelect;
