import Select from "react-select";
import { db } from "../../utils/firebase_config";
import { collection, getDocs, onSnapshot } from "@firebase/firestore";
import { useEffect, useState } from "react";

const SchoolSelect = ({ setSchool }) => {
  const [schoolList, setSchoolList] = useState([]);

  const collectionRef = collection(db, "school");

  useEffect(() => {
    //-----------Vlan list---------
    onSnapshot(collectionRef, (querySnapshot) => {
      const _schoolList = [];
      querySnapshot.forEach((doc) => {
        var data = doc.data();
        var schoolId = data.name;
        schoolId = parseInt(schoolId.substring(4));

        _schoolList.push({
          value: { id: doc.id, ...doc.data() },
          index: schoolId,
          label: doc.data().name,
        });
      });
      var _data = _schoolList;
      _data.sort((a, b) => a.label.localeCompare(b.label));
      console.log(_data);
      setSchoolList(_data);
    });
  }, []);
  return (
    <div className="">
      <Select
        options={schoolList}
        placeholder="Please Select School"
        onChange={(value) => {
          var data = value.value;
          setSchool(data);
        }}
      />
    </div>
  );
};

export default SchoolSelect;
