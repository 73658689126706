import { db } from "../../utils/firebase_config";
import {
  collection,
  query,
  where,
  getDocs,
  onSnapshot,
} from "firebase/firestore";
import { useEffect, useState } from "react";
import NodeListTable from "./node_list_table.component";
import NodeHeader from "./node_header_component";

const NodeList = ({ vlanId = "" }) => {
  const [nodeList, setNodeList] = useState([]);
  const [department, setDepartment] = useState([]);
  const nodeCollectionRef = collection(db, "node");
  const [isLoading, setIsLoading] = useState(false);

  const [filteredData, setFilteredData] = useState();

  const filterByDepartment = (searchTerm) => {
    if (searchTerm !== "") {
      const newNodeList = nodeList.filter((node) => {
        const result = node.department.id === searchTerm.id;

        return result;
      });
      setFilteredData(newNodeList);
    }
  };
  const filterBySchool = (searchTerm) => {
    if (searchTerm !== "") {
      const newNodeList = nodeList.filter((node) => {
        const result = node.department.school.id === searchTerm.id;

        return result;
      });
      setFilteredData(newNodeList);
    }
  };

  const filterByVlan = (searchTerm) => {
    if (searchTerm !== "") {
      const newNodeList = nodeList.filter((node) => {
        const result = node.vlan.id === searchTerm.id;

        return result;
      });
      setFilteredData(newNodeList);
    }
  };
  const filterByName = (searchTerm) => {
    if (searchTerm !== "") {
      const newNodeList = nodeList.filter((node) => {
        const _searchTerm = searchTerm.label.toLowerCase();
        const _searchObject = Object.values({
          name: node.name,
        })
          .join("")
          .toLowerCase();

        const result = _searchObject.includes(_searchTerm);

        return result;
      });
      setFilteredData(newNodeList);
    }
  };

  const filterByIpAddress = (searchTerm) => {
    if (searchTerm !== "") {
      const newNodeList = nodeList.filter((node) => {
        const _searchTerm = searchTerm.label.toLowerCase();
        const _searchObject = Object.values({
          ipAddress: node.ipAddress,
        })
          .join("")
          .toLowerCase();

        const result = _searchObject.includes(_searchTerm);

        return result;
      });
      setFilteredData(newNodeList);
    }
  };

  function getNodeList(vlanId) {
    if (vlanId === 0) return;

    if (vlanId !== "") {
      setIsLoading(true);

      const q = query(collection(db, "node"), where("vlan.id", "==", vlanId));

      onSnapshot(q, (querySnapshot) => {
        const nodes = [];
        querySnapshot.forEach((doc) => {
          nodes.push({ id: doc.id, ...doc.data() });
        });
        setNodeList(nodes);
      });
    } else {
      const collectionRef = collection(db, "node");
      onSnapshot(collectionRef, (querySnapshot) => {
        const nodes = [];
        querySnapshot.forEach((doc) => {
          nodes.push({ id: doc.id, ...doc.data() });
        });
        setNodeList(nodes);
      });
    }
    setIsLoading(false);
  }

  useEffect(() => {
    //-------Department------
    getDocs(nodeCollectionRef).then((snapShot) => {
      var _departmentList = [{ value: "all", label: "ALL" }];

      var result = _departmentList.concat(
        snapShot.docs.map((doc) => {
          return {
            value: doc.id,
            label: doc.data().name,
            data: doc.data(),
          };
        })
      );

      setDepartment(result);
    });

    // -------Node Get docs by vlan id------

    getNodeList(vlanId);
    console.log(nodeList);
  }, []);
  const clearFilteredData = () => {
    console.log("clearing filtered data");
    setFilteredData(null);
  };

  return (
    <div>
      <NodeHeader onFilterReset={clearFilteredData} />
      {isLoading ? (
        <div>
          <div>{vlanId}</div>loading...
        </div>
      ) : (
        <NodeListTable
          nodeList={filteredData == null ? nodeList : filteredData}
          departmentList={department}
          onSelectDepartment={filterByDepartment}
          onNodeSelect={filterByName}
          onIpSelect={filterByIpAddress}
          onVlanSelect={filterByVlan}
          onSchoolSelect={filterBySchool}
        />
      )}
    </div>
  );
};

export default NodeList;
