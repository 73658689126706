import NewVlan from "./newVlan_component";
const VlanHeader = () => {
  return (
    <div className="flex flex-row items-center  justify-between py-2 px-3  ml-5 mr-5 border-2 bg-green-100 ">
      <h1 className="xl:sticky text-2xl ">VLan Page</h1>
      <NewVlan />
    </div>
  );
};

export default VlanHeader;
